import React from "react";
import "../styles/index.css";
import Navbar from "../components/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Styles from "../styles/links.js";
import { SVGS } from "../assets";
import { Row } from "react-bootstrap";
import * as Components from "../styles/index";
import SmallFooter from "../components/SmallFooter";
import SEO from "../components/SEO";

function Links({ data }) {
  return (
    <Styles.Main>
      <SEO />
      <Styles.Container fluid="sm">
        <Styles.Row className="justify-content-md-center">
          <Styles.Content>
            <Styles.Logo
              src={SVGS.horizontalLogo}
              className="d-inline-block align-top"
              alt="PetVitta Aracaju"
            />
            <Styles.Links>
              <Styles.Item
                href="https://api.whatsapp.com/send?phone=5579999610101"
                alt="WhatsApp PetVitta Aracaju"
                onClick={() => {
                  window.gtag("event", "links_click_whatsapp");
                  return true;
                }}
              >
                <Styles.Icon src={SVGS.whatsapp} />
                WhatsApp
              </Styles.Item>
              <Styles.Item
                href="https://goo.gl/maps/smqozfPubxHdF7Ps7"
                alt="Como chegar PetVitta Aracaju"
                onClick={() => {
                  window.gtag("event", "links_click_como_chegar");
                  return true;
                }}
              >
                <Styles.Icon src={SVGS.pin} />
                Como chegar
              </Styles.Item>
              <Styles.Item
                href="https://forms.gle/rD5FogMp5TczWs9D6"
                alt="Pesquisa produtos PetVitta Aracaju"
              >
                <Styles.Icon src={SVGS.notes} />
                <u>Pesquisa de produtos 🤩</u>
              </Styles.Item>
              <Styles.Item
                href="http://petvittaaracaju.com.br/"
                alt="Webiste PetVitta Aracaju"
                onClick={() => {
                  window.gtag("event", "links_click_website");
                  return true;
                }}
              >
                <Styles.Icon src={SVGS.website} />
                Site
              </Styles.Item>
              <Styles.Item
                href="http://loja.petvittaaracaju.com.br/"
                alt="Loja PetVitta Aracaju"
                onClick={() => {
                  window.gtag("event", "links_click_loja_online");
                  return true;
                }}
              >
                <Styles.Icon src={SVGS.shoppingCart} />
                Loja Online
              </Styles.Item>
            </Styles.Links>
            <SmallFooter />
          </Styles.Content>
        </Styles.Row>
      </Styles.Container>
    </Styles.Main>
  );
}

export default Links;
