import styled from "styled-components";
import { Row as BTRow, Col, Container as BTContainer } from "react-bootstrap";
import { SVGS, COLORS, IMAGES } from "../assets";

export const Row = styled(BTRow)`
  text-align: center;
`;

export const Main = styled.main`
  background-color: ${COLORS.bege};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Logo = styled.img`
  max-height: 100%;
  max-width: 356px;
  width: auto;
  margin-bottom: 20px;
`;

export const Container = styled(BTContainer)`
  padding: 40px;
`;

export const Content = styled(Col)`
  /* background-color: ${COLORS.bege}; */
`;

export const Icon = styled.img`
  with: 24px;
  height: 24px;
  margin-right: 10px;
  fill: white;
  stroke: white;
`;

export const Links = styled.div`
  margin-top: 24px;
  flex-direction: column;
  display: flex;
`;

export const Link = styled.a`
  color: ${COLORS.white} !important;
  font-weight: 700;
`;

export const Item = styled.a.attrs(() => ({
  target: "_blank",
}))`
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 30px;
  flex-direction: row;
  font-weight: 500;
  background-color: ${COLORS.carrot};
  color: ${COLORS.white} !important;
  &:hover {
    color: ${COLORS.white};
  }
`;
